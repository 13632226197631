import React, { useState, useCallback } from "react";

import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import ReactCrop from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";

import { cropImage, imageToBase64 } from "../utils";

export interface ICroppedImage {
  sourceImageBase64: string;
  croppedImageBase64: string;
  ax: number;
  ay: number;
  bx: number;
  by: number;
}
interface IImageCropper {
  src: string;
  onImageCropped: (croppedImage: ICroppedImage) => void;
  onChangeImage: () => void;
}

const useStyles = makeStyles({
  layout: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  cropperContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& *:not(.ReactCrop__crop-selection)": {
      height: "100%",
    },
  },
  cropper: {
    margin: "auto",
  },
  buttons: {
    width: "100%",
    padding: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
});

function ImageCropper({ src, onImageCropped, onChangeImage }: IImageCropper) {
  const [imageElement, setImageElement] = useState<HTMLImageElement | null>(
    null
  );
  const [crop, setCrop] = useState<ReactCrop.Crop>({});
  const [validCrop, setValidCrop] = useState<ReactCrop.Crop | null>(null);

  const classes = useStyles();

  const handleCropChange = useCallback(
    (crop) => {
      setCrop(crop);
    },
    [setCrop]
  );

  const handleCropComplete = useCallback(
    (crop) => {
      if (crop.width && crop.height) {
        setValidCrop(crop);
      }
    },
    [setValidCrop]
  );

  const handleValidateCrop = useCallback(() => {
    if (!imageElement) {
      throw new Error("Image is not loaded");
    }
    if (!validCrop) {
      throw new Error("Crop is not complete");
    }
    if (
      validCrop.width === undefined ||
      validCrop.height === undefined ||
      validCrop.x === undefined ||
      validCrop.y === undefined
    ) {
      throw new Error("Crop values are incomplete");
    }

    const sourceImageBase64 = imageToBase64(imageElement);
    const croppedImageBase64 = cropImage(imageElement, validCrop);
    const scaleX = imageElement.naturalWidth / imageElement.width;
    const scaleY = imageElement.naturalHeight / imageElement.height;

    onImageCropped({
      sourceImageBase64,
      croppedImageBase64,
      ax: Math.round(validCrop.x * scaleX),
      ay: Math.round(validCrop.y * scaleY),
      bx: Math.round((validCrop.x + validCrop.width) * scaleX),
      by: Math.round((validCrop.y + validCrop.height) * scaleY),
    });
  }, [imageElement, validCrop, onImageCropped]);

  return (
    <div className={classes.layout}>
      <Typography variant="h4">
        Veuillez sélectionner la zone de la chaudière
      </Typography>
      <div className={classes.cropperContainer}>
        <div style={{ height: "60vh" }}>
          <ReactCrop
            className={classes.cropper}
            src={src}
            crop={crop}
            onImageLoaded={setImageElement}
            onChange={handleCropChange}
            onComplete={handleCropComplete}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <Button variant="outlined" onClick={onChangeImage}>
          Utiliser une autre photo
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!validCrop}
          onClick={handleValidateCrop}
        >
          Valider
        </Button>
      </div>
    </div>
  );
}

export default ImageCropper;
