import React from "react";

import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import LinkedinIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";

import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
} from "react-share";

const useStyles = makeStyles({
  logo: {
    width: 300,
    marginBottom: "1em",
  },
  shareIcons: {
    width: "8em",
    display: "flex",
    justifyContent: "space-between",
    color: "grey",
  },
  candidate: {
    marginTop: "1em",
    marginBottom: "1em",
  },
  subscribe: {
    marginTop: "2em",
    marginBottom: "2em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  emailInput: {
    padding: "0px 0px",
    marginRight: "2em",
    flexGrow: 1,
  },
  antispam: {
    position: "absolute",
    left: "-5000px",
  },
});

const urlToShare = "https://sherlock.selfcity.fr/";

const PresentationText = () => {
  const classes = useStyles();

  return (
    <div>
      <img
        src="/logo.png"
        className={classes.logo}
        alt="Sherlock par Selfcity"
      />
      <Typography
        style={{ fontWeight: "bolder", fontSize: "2em", lineHeight: "1.3em" }}
      >
        Modèle et marque de votre chaudière à partir d’une simple photo&nbsp;📷
      </Typography>
      <div className={classes.shareIcons}>
        <LinkedinShareButton url={urlToShare}>
          <LinkedinIcon titleAccess="Partager sur LinkedIn" />
        </LinkedinShareButton>
        <TwitterShareButton url={urlToShare}>
          <TwitterIcon titleAccess="Partager sur Twitter" />
        </TwitterShareButton>
        <FacebookShareButton url={urlToShare}>
          <FacebookIcon titleAccess="Partager sur Facebook" />
        </FacebookShareButton>
        <EmailShareButton url={urlToShare}>
          <EmailIcon titleAccess="Partager par e-mail" />
        </EmailShareButton>
      </div>
      <Typography variant="h4">Ceci est un POC</Typography>
      <Typography>
        À terme, Sherlock&nbsp;🕵️‍♂️ doit pouvoir identifier tous les éléments
        présents dans une installation de plomberie/chauffage à partir d’une
        simple photo.
      </Typography>
      <Typography>
        Un pré-diagnostic précis, c’est un plombier mieux préparé et moins de
        déplacements pour un dépannage de qualité avec un impact environnemental
        réduit.
      </Typography>
      <Typography variant="h4">Pourquoi le publier&#8239;?</Typography>
      <Typography>
        Chez Selfcity, nous travaillons à créer le futur de la maintenance des
        logements : un service intelligent, à la fois meilleur pour nos clients,
        pour nos employés et pour la planète.&nbsp;🌍
      </Typography>
      <Typography>
        Les défis tech associés à notre vision nous passionnent et une passion,
        eh bien ça se partage&#8239;!
      </Typography>
      <Typography>
        Nous publierons donc régulièrement sur cette page nos dernières avancées
        R&amp;D.&nbsp;😉
      </Typography>
      <form
        action="https://selfcity.us1.list-manage.com/subscribe/post?u=f6a5017b92e9d8fbc655978d5&amp;id=42bb74fc0a"
        method="post"
      >
        <div className={classes.subscribe}>
          <TextField
            type="email"
            name="EMAIL"
            color="primary"
            placeholder="Adresse e-mail"
            className={classes.emailInput}
          />
          <div className={classes.antispam} aria-hidden="true">
            <input
              type="text"
              name="b_f6a5017b92e9d8fbc655978d5_42bb74fc0a"
              tabIndex={-1}
              value=""
            />
          </div>
          <div>
            <Button
              type="submit"
              name="subscribe"
              color="primary"
              variant="contained"
              size="small"
            >
              Tenez-moi au courant&#8239;!
            </Button>
          </div>
        </div>
      </form>
      <Typography className={classes.candidate}>
        Pour rejoindre notre équipe R&amp;D&#8239;:{" "}
        <Link href="mailto:jobs@selfcity.fr">jobs@selfcity.fr</Link>
      </Typography>
      <Typography variant="h4">Historique</Typography>
      <Table>
        <TableHead>
          <TableCell>Date</TableCell>
          <TableCell>Version</TableCell>
          <TableCell>Nouveautés / Améliorations</TableCell>
        </TableHead>
        <TableBody>
          <TableCell>30 mars 2021</TableCell>
          <TableCell>1.0</TableCell>
          <TableCell>
            Identification post-crop&#8239;; bibliothèque de 86 modèles
          </TableCell>
        </TableBody>
      </Table>
    </div>
  );
};

export default PresentationText;
