import { makeStyles } from "@material-ui/styles";

import PresentationText from "./PresentationText";
import SherlockTester from "./SherlockTester";

const useStyles = makeStyles({
  layout: {
    margin: 40,
    display: "flex",
    height: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: 80,
  },
  info: {
    flex: 0.4,
    minWidth: 300,
    height: "100%",
  },
  tester: {
    flex: 0.6,
    minWidth: 300,
    height: "100%",
  },
});

const App = () => {
  const classes = useStyles();

  return (
    <div className={classes.layout}>
      <div className={classes.info}>
        <PresentationText />
      </div>
      <div className={classes.tester}>
        <SherlockTester />
      </div>
    </div>
  );
};

export default App;
