import React, { useCallback, useState } from "react";

import ImageDropZone from "./ImageDropZone";
import ImageCropper, { ICroppedImage } from "./ImageCropper";

interface IImageInput {
  onImageCropped: (croppedImage: ICroppedImage) => void;
}

const ImageInput = ({ onImageCropped }: IImageInput) => {
  const [src, setSrc] = useState<string | null>(null);

  const handleImageSelected = useCallback((imageFile: File) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setSrc(reader.result as string);
    });
    reader.readAsDataURL(imageFile);
  }, []);

  const handleChangeImage = useCallback(() => {
    setSrc(null);
  }, []);

  return src ? (
    <ImageCropper
      src={src}
      onImageCropped={onImageCropped}
      onChangeImage={handleChangeImage}
    />
  ) : (
    <ImageDropZone onImageSelected={handleImageSelected} />
  );
};

export default ImageInput;
