import React from "react";

interface ISherlockErrorDisplay {
  error: Error;
}

const SherlockErrorDisplay = ({ error }: ISherlockErrorDisplay) => {
  return <div>Une erreur est survenue : {error.message}</div>;
};

export default SherlockErrorDisplay;
