import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

interface IImageDropZone {
  onImageSelected: (imageFile: File) => void;
}

const useStyles = makeStyles({
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 100,
    padding: 100,
    borderWidth: 10,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      borderColor: "#34bf8355",
    },
  },
});

function ImageDropZone({ onImageSelected }: IImageDropZone) {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      onImageSelected(acceptedFiles[0]);
    },
    [onImageSelected]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <Typography variant="h4">Sélectionner une photo</Typography>
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="h5">Déposez la photo ici…</Typography>
        ) : (
          <Typography variant="h5">
            Faites glisser et déposer la photo ici, ou cliquez pour sélectionner
            la photo
          </Typography>
        )}
      </div>
    </>
  );
}

export default ImageDropZone;
