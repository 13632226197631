import { ICroppedImage } from "./components/ImageCropper";
import { ISherlockResult } from "./types";

export const imageToBase64 = (image: HTMLImageElement): string => {
  const canvas = document.createElement("canvas");
  canvas.width = image.naturalWidth;
  canvas.height = image.naturalHeight;
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Could not get canvas context");
  }

  ctx.drawImage(image, 0, 0);

  return canvas.toDataURL("image/jpeg").split(";base64,")[1];
};

export const cropImage = (
  image: HTMLImageElement,
  crop: ReactCrop.Crop
): string => {
  if (
    crop.width === undefined ||
    crop.height === undefined ||
    crop.x === undefined ||
    crop.y === undefined
  ) {
    throw new Error("Crop values are incomplete");
  }

  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Could not get canvas context");
  }

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return canvas.toDataURL("image/jpeg").split(";base64,")[1];
};

interface IDataObject {
  [key: string]: string | number | boolean;
}

const encodeData = (data: IDataObject) => {
  return Object.entries(data)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
};

export const recordResult = async (
  base64Image: string,
  result: ISherlockResult,
  userConfirmed?: boolean
) => {
  const data = {
    "form-name": "sherlock-submissions",
    name: result.ref_name,
    similarity: result.ref_similarity,
    score: result.similarity_score,
    inputImage: base64Image,
    outputImage: result.ref_img,
    ...(userConfirmed !== undefined ? { userConfirmed } : {}),
  };

  const encodedData = encodeData(data);

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encodedData,
  })
    .then(() => console.log("Form successfully submitted"))
    .catch((error) => alert(error));
};

export const askSherlock = async (croppedImage: ICroppedImage) => {
  return fetch(
    "https://app-c18fd1c0-c147-43d7-8305-377234504f71.cleverapps.io/",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        image: croppedImage.croppedImageBase64,
        image_complete: croppedImage.sourceImageBase64,
        user_box: [
          croppedImage.ax,
          croppedImage.ay,
          croppedImage.bx,
          croppedImage.by,
        ],
      }),
    }
  ).then(async (response) => {
    const result: ISherlockResult = await response.json();
    if (!result.ref_similarity) {
      recordResult(croppedImage.croppedImageBase64, result);
    }
    return result;
  });
};
