import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { frFR } from "@material-ui/core/locale";

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#34bf83", contrastText: "#ffffff" },
    },
    typography: {
      fontFamily: "Raleway",
      body1: {
        marginTop: "1em",
        marginBottom: "1em",
      },
      h4: {
        fontFamily: "Spinnaker",
        marginTop: "1em",
        marginBottom: "0.5em",
      },
      h6: {
        fontFamily: "Spinnaker",
      },
    },
  },
  frFR
);

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
