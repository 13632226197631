import React, { useCallback, useMemo, useState } from "react";

import { makeStyles } from "@material-ui/styles";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Alert from "@material-ui/lab/Alert";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";

import { ISherlockResult } from "../types";
import { recordResult } from "../utils";

interface ISherlockResultDisplay {
  inputBase64Image: string;
  sherlockResult: ISherlockResult;
  onTryAgain: () => void;
}

const useStyles = makeStyles({
  layout: {
    height: "calc(100vh - 100px)",
    display: "flex",
    flexDirection: "column",
  },
  subtitle: {
    marginTop: "2em",
    marginBottom: "3em",
  },
  question: {
    height: "4em",
    marginTop: "2em",
    display: "flex",
    alignItems: "center",
  },
  questionText: {
    display: "inline",
  },
  questionButtons: {
    display: "inline-flex",
    marginLeft: "1em",
    width: "10em",
    justifyContent: "space-between",
  },
  image: {
    objectFit: "contain",
    maxHeight: "50vh",
    maxWidth: "100%",
    margin: "auto",
  },
  info: {
    marginBottom: "4em",
  },
  key: {
    textAlign: "right",
    marginRight: "1em",
  },
  value: {
    marginLeft: "1em",
  },
  tryAgain: {
    textAlign: "right",
    marginTop: "2em",
  },
});

const SherlockResultDisplay = ({
  inputBase64Image,
  sherlockResult,
  onTryAgain,
}: ISherlockResultDisplay) => {
  const inputImageSrc = useMemo(
    () => `data:image/jpeg;base64,${inputBase64Image}`,
    [inputBase64Image]
  );
  const outputImageSrc = useMemo(
    () => `data:image/jpeg;base64,${sherlockResult.ref_img}`,
    [sherlockResult.ref_img]
  );

  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [questionAnswered, setQuestionAnswered] = useState(false);

  const handleOpenDialog = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleAnswer = useCallback(
    (answer: boolean) => {
      recordResult(inputBase64Image, sherlockResult, answer);
      setQuestionAnswered(true);
    },
    [inputBase64Image, sherlockResult]
  );

  const handleAnswerYes = useCallback(() => {
    handleAnswer(true);
  }, [handleAnswer]);

  const handleAnswerNo = useCallback(() => {
    handleAnswer(false);
  }, [handleAnswer]);

  return (
    <div className={classes.layout}>
      <Typography variant="h4">
        {sherlockResult.ref_similarity
          ? "Correspondance trouvée ! 😁"
          : "Pas de correspondance trouvée… 😢"}
      </Typography>
      {sherlockResult.ref_similarity ? (
        <>
          <div className={classes.subtitle}>
            <Typography variant="h6">
              Modèle&#8239;:{" "}
              <span
                style={{
                  backgroundColor: "#faff8b",
                  fontWeight: "bold",
                }}
              >
                {sherlockResult.ref_name}
              </span>
            </Typography>
            <Typography variant="h6">
              Correspondance&#8239;:&nbsp;&nbsp;&nbsp;
              <span className="circle-sketch-highlight">
                {sherlockResult.similarity_score.toLocaleString("fr", {
                  maximumFractionDigits: 2,
                })}
                &#8239;%
              </span>
            </Typography>
            {questionAnswered ? (
              <Alert severity="success" className={classes.question}>
                <Typography className={classes.questionText}>
                  Merci pour votre retour&#8239;!
                </Typography>
              </Alert>
            ) : (
              <Alert
                severity="info"
                icon={<HelpOutlineOutlinedIcon />}
                className={classes.question}
              >
                <Typography className={classes.questionText}>
                  Est-ce bien la bonne chaudière&#8239;?
                </Typography>
                <span className={classes.questionButtons}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleAnswerYes}
                  >
                    Oui
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleAnswerNo}
                  >
                    Non
                  </Button>
                </span>
              </Alert>
            )}
          </div>
          <Grid container>
            <Grid item container alignItems="center" xs={12} sm={6}>
              <img
                className={classes.image}
                src={inputImageSrc}
                alt="Chaudière soumise"
              />
            </Grid>
            <Grid item container alignItems="center" xs={12} sm={6}>
              <img
                className={classes.image}
                src={outputImageSrc}
                alt="Chaudière reconnue"
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Typography variant="h6" className={classes.subtitle}>
            Il est très possible que votre chaudière ne fasse pas encore partie
            de notre petite bibliothèque de 86 modèles…{" "}
            <Link href="#" onClick={handleOpenDialog}>
              Voir les chaudières référencées
            </Link>
            .
          </Typography>
          <img
            className={classes.image}
            src={inputImageSrc}
            alt="Chaudière soumise"
          />
        </>
      )}

      <div className={classes.tryAgain}>
        <Button variant="contained" color="primary" onClick={onTryAgain}>
          Réessayer
        </Button>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Modèles de chaudières supportés
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <ul>
              <li>Acier - 25 Kw B-max</li>
              <li>Atlantic - Naia Chauffage seul</li>
              <li>Atlantic - Pluton 5040</li>
              <li>Atlantic - Tweetie Nox Micro Cheminee</li>
              <li>Auer - Lelia Accumulation 160</li>
              <li>Auer - Pulsatoire 160-2 avec Ballon BRV</li>
              <li>Auer - Gialix 120 MA Tri</li>
              <li>Bosch - 4500 W WBC 25H RN DO</li>
              <li>Bosch - Condens 4500 FM FBGC 25C RC 120N DO</li>
              <li>Bosch - GC8300i W 3540 C</li>
              <li>
                Bosch - OLIO 1500FM Chauffage eau chaude sanitaire FBON 20C T80
              </li>
              <li>Bronpi - Mini Hydrobox</li>
              <li>Chappee - Initia Plus 1.28 HTE</li>
              <li>Chappee - Klista GBiGBVi 24</li>
              <li>Chappee - Luna ST Blue Flirt 2.24 CF Cheminee</li>
              <li>Chappee - Odia HTE chauffage seul</li>
              <li>De Dietrich - ZENA MS 24 MI VMC</li>
              <li>De Dietrich - CBB 15E</li>
              <li>De Dietrich - CF 124 CSE</li>
              <li>De Dietrich - Modulens G AGC 15 25 </li>
              <li>De Dietrich - Naneo EMC-M 24</li>
              <li>De Dietrich - Twineo EGC 25</li>
              <li>De Dietrich - Vivadens MCR 2428 MI PLUS</li>
              <li>Domusa - BioClass HM 10</li>
              <li>Domusa - JAKA HFS 80</li>
              <li>Domusa - minny</li>
              <li>Domusa - Avanttia 15 DX</li>
              <li>Domusa - HDCS 1015 ECS Inox</li>
              <li>Domusa - HDEEM 4590</li>
              <li>Domusa - JAKA 20 HFD CONDENS</li>
              <li>Elm Leblanc - Acleis NGLM 24-7XN BAS Nox</li>
              <li>Elm Leblanc - Acleis GVM C 24-4H </li>
              <li>Elm Leblanc - Condens Stellis SVB C 2275-3H</li>
              <li>Elm Leblanc - Egalis Condens GVB C 24-1H</li>
              <li>Elm Leblanc - Megalis 22kw GVA C21-5M</li>
              <li>Elm Leblanc - Soltis Condens ELSC 16</li>
              <li>Elm Leblanc - Acleis NGLM 23-7MN5 VMC</li>
              <li>Ferroli - Atlas D63</li>
              <li>Ferroli - Atlas D 37 Unit</li>
              <li>Ferroli - Bluehelix B S 32 K 100</li>
              <li>Ferroli - Bluehelix Pro RRT 28 C</li>
              <li>Ferroli - DOMUSFIRE F24</li>
              <li>Ferroli - Bluehelix B 35</li>
              <li>Frisquet - Hydroconfort Condensation Visio</li>
              <li>Frisquet - Hydromotrix Condensation Visio</li>
              <li>Frisquet - Hydromotrix Tradition</li>
              <li>Frisquet - Prestige Condensation Visio</li>
              <li>Gretel - DCSV 14 Kw</li>
              <li>Gretel - S 9 Kw</li>
              <li>HydroConfort - 27 Bronpi</li>
              <li>Klimosz - Wally</li>
              <li>Kospel - EKCO. LN2MF-12 Elymatic Mono</li>
              <li>Kospel - EKCO. M1N 8 Z Elymatic Mono Tri</li>
              <li>Kospel - EKD.M3 - 08</li>
              <li>Radiant - RCA 2540-DSC 2540 NOx</li>
              <li>Riello - GITRE 5</li>
              <li>Riello - ELNUR RIELEC 15 NE</li>
              <li>Riello - Elnur Rielec 15 NES avec ballon dECS</li>
              <li>Riello - Residence Colonna Condens KV130 28 ISC</li>
              <li>Riello - Tau Unit 35</li>
              <li>Saunier Duval - Isomax</li>
              <li>Saunier Duval - ThemaClassic</li>
              <li>Saunier Duval - ThemaFast</li>
              <li>Saunier Duval - ThemaPlus</li>
              <li>Sime - Estelle 5 Ballon Inox 110L Cheminee</li>
              <li>Sime - RONDO Estelle 7 OF Cheminee</li>
              <li>Tronic - Heat 3500 4 FR Bosch</li>
              <li>Unical - Fokolus FK 20</li>
              <li>Unical - Alkon 28</li>
              <li>Unical - Alkon 35 B 60</li>
              <li>Unical - Alkon 50 R</li>
              <li>Viadrus - Hercules U 26 4 elements</li>
              <li>Viadrus - Hefaistos P1 a gazeification</li>
              <li>ViessMann - VITODENS 111-F</li>
              <li>ViessMann - VITODENS 222-F B2SF</li>
              <li>ViessMann - Vitocrossal 300</li>
              <li>ViessMann - VITODENS 100-W</li>
              <li>ViessMann - VITODENS 200-W</li>
              <li>ViessMann - VITODENS 222-F B2TF</li>
              <li>ViessMann - Vitola 200</li>
              <li>ViessMann - Vitorondens 200-T</li>
              <li>Wolf - CSZ-2 14R</li>
              <li>Wolf - FGB-K 35</li>
              <li>Wolf - CGB-2 Chauffage Seul</li>
              <li>Wolf - CGB 35 Chauffage Seul</li>
              <li>Wolf - CGS-2L </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SherlockResultDisplay;
