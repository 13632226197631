import React, { useCallback, useState } from "react";

import ImageInput from "./ImageInput";
import AskingSherlock from "./AskingSherlock";
import SherlockErrorDisplay from "./SherlockErrorDisplay";
import SherlockResultDisplay from "./SherlockResultDisplay";

import { ISherlockResult } from "../types";
import { askSherlock } from "../utils";
import { ICroppedImage } from "./ImageCropper";

const SherlockTester = () => {
  const [croppedImage, setCroppedImage] = useState<ICroppedImage | null>(null);
  const [sherlockResult, setSherlockResult] = useState<ISherlockResult | null>(
    null
  );
  const [sherlockError, setSherlockError] = useState<Error | null>(null);

  const handleImageCropped = useCallback((croppedImage: ICroppedImage) => {
    setCroppedImage(croppedImage);
    askSherlock(croppedImage)
      .then((result) => {
        setSherlockResult(result);
      })
      .catch((error) => {
        setSherlockError(error);
      });
  }, []);

  const handleTryAgain = useCallback(() => {
    setCroppedImage(null);
    setSherlockError(null);
    setSherlockResult(null);
  }, []);

  return croppedImage ? (
    sherlockResult ? (
      <SherlockResultDisplay
        inputBase64Image={croppedImage.croppedImageBase64}
        sherlockResult={sherlockResult}
        onTryAgain={handleTryAgain}
      />
    ) : sherlockError ? (
      <SherlockErrorDisplay error={sherlockError} />
    ) : (
      <AskingSherlock />
    )
  ) : (
    <ImageInput onImageCropped={handleImageCropped} />
  );
};

export default SherlockTester;
