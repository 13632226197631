import { CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  layout: {
    display: "flex",
    borderColor: "grey",
    padding: "10em",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    marginLeft: "2em",
    color: "grey",
  },
});

const AskingSherlock = () => {
  const classes = useStyles();

  return (
    <div className={classes.layout}>
      <CircularProgress color="primary" />
      <Typography variant="h4" className={classes.text}>
        Recherche de correspondance…
      </Typography>
    </div>
  );
};

export default AskingSherlock;
